import React from "react"

const ListItem = ({ item }) => {
  return (
    <li className={item.label ? "labeled" : ""}>
      <h3>
        {item.title}
        {item.subTitle && <span className="subTitle">{item.subTitle}</span>}
      </h3>
      <div>
        {typeof item.text === "string" ? <p>{item.text}</p> : item.text}
      </div>

      {item.label && (
        <div className={`${item.label.dark ? "dark" : "orange"} label`}>
          {item.label.text}
        </div>
      )}
    </li>
  )
}

export default ListItem
